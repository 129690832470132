<template>
    <form-card card-title="Record Batch Mortalities" card-sub-title="" class="bg-light">
        <form v-on:submit.prevent="mortalityForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div>
                            <label>How many lost today?</label>
                            <input class="form-control" type="number" v-model="form.mortality_count" min="0" onwheel="this.blur()">
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="text-center">
                <button type="submit" class="btn btn-primary my-4"><i class="ni ni-check-bold"></i> Save Record</button>
            </div>
        </form>
    </form-card>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import NoButtonWhiteCardVue from '../../../components/Cards/NoButtonWhiteCard.vue'
export default {
  name: 'ProductionMortalityForm',
  components:{
      'form-card': NoButtonWhiteCardVue
  },
  props:[
      'batchId'
  ],
  computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
  data(){
      return{
          form:{
              batch_id: this.batchId,
              mortality_count: null,
          }
      }
  },
  methods:{
        mortalityForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/admin/production/mortalities', this.form,{ 
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then((response) => {
                //Perform Success Action
                this.$moshaToast(response.data.message,{
                    type: 'success'
                });
                this.form.mortality_count = null
                this.$emit('success',true)
                
            })
            .catch((error) => {
            // error.response.status Check status code
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
            .finally(() => {
                //Perform other dependent actions
                loader.hide()
            });
        }
  }
}
</script>