<template>
    <div>
        <stats-card card-sub-title="" card-title="Mortality Reports" class="bg-white text-dark"> 
            <h5 class="text-white mt-2 bg-danger p-2">BATCH NAME: {{batchName}}</h5>
            <h3 class="badge badge-danger">Total Losses: <span>{{totalLosses}}</span></h3>
            <ul v-if="records">
               <li v-for="record in records" :key="record.mortality_id">
                   <span class="text-red">{{record.mortality_count}}</span> units of {{record.batch.livestock_type}} stock was recorded as LOST on <span class="badge badge-primary">{{record.created_at}}</span>

                   <span class="text-right">
                       <button type="button" @click="deleteRecord(record.mortality_id)" class="btn btn-sm"><i class="fas fa-times text-red"></i> </button>
                   </span>
               </li>
            </ul>
            <div v-else> Nothing to see</div>
        </stats-card>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import StatsCardVue from '../../../components/Cards/StatsCard.vue';
export default {
    name: 'MortalityReports',
    props:[
        'batchId', 'batchName'
    ],
    computed : {
        ...mapState({
            authToken : state => state.authToken
        }),
        totalLosses() {
            if (this.records) {
                return this.records.reduce((acc, item) => acc + item.mortality_count, 0);                
            }else{
                return null
            }
        },
    },
    data(){
        return{
            records: null
        }
    },
    components:{
        'stats-card':StatsCardVue
    },
    mounted(){
        this.fetchRecords()        
    },
    methods:{
        fetchRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/production/mortalities?batch_id='+this.batchId,{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.records = response.data.data
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
        },

        deleteRecord(record){
            if(confirm("Do you really want to delete?")){
                axios.delete(process.env.VUE_APP_BASEURL+'/admin/production/mortalities/'+record+'?confirm=yes',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                .then(() => {
                    this.$moshaToast('Deleted Successfully!!',{
                        type: 'success'
                    });                        
                    this.fetchRecords()
                })
                .catch(error => {
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                })  
            }
        },
    }
}
</script>
<style scoped>
ul{
    margin-left: 0;
    padding-inline-start: 5px;
}
li{
    border-bottom: 1px solid rgb(248, 128, 128);
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 13px;
}
.badge-danger, .badge-primary{
    color:#000;
}
</style>